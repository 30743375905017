import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { HttpService } from "@core/services/http.service";
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
@Injectable({
  providedIn: "root",
})
export class EcommerceService implements Resolve<any> {
  // Public
  public productList: Array<any>;
  public cartList: Array<[]>=[];
  public onProductList: BehaviorSubject<any>;
  public onCartListChange: BehaviorSubject<any>;
  public onOrderAddChanged: BehaviorSubject<any>;

  // Private
  private idHandel;

  private sortRef = (key) => (a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }
    return comparison;
  };

  constructor(
    private _httpClient: HttpClient,
    private _httpService: HttpService, 
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {
    this.onProductList = new BehaviorSubject({});
    this.onCartListChange = new BehaviorSubject({});
    this.onOrderAddChanged= new BehaviorSubject({});
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.idHandel = route.params.id;

    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getProductRows()
      ]).then(() => {
        resolve();
      }, reject);
    });
  }
  createData(url: string, value: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpService.getLocalStorageInfo("token").then((token) => {
        this._httpService.getHttpJsonPost(url, value, token).then((res) => {
          if (res.error != true) {
            resolve(res);
          } else {
            reject(new Error("Crate data item failed."));
          }
        }, reject);
      });
    });
  }
  getProductRows(): Promise<any[]> {
    const url: string = "product";
    return new Promise((resolve, reject) => {
      this._httpService.getLocalStorageInfo("token").then((token) => {
        this._httpService.getHttpJsonGet(url, token).then((res) => {
          if (res.error != true) {
            this.productList = res.result;
            this.onProductList.next(res.result);
            resolve(this.productList);
          } else {
            reject(new Error("No data found."));
          }
        });
      });
    });
  }
  sortRange(startRange, endRange, type) {
    if (type !== "all" ) {
      const sortRangeProductList = [];
      this.productList.forEach((element) => {
        if (type === "priceRange1") {
          if (element.price <= endRange) {
            sortRangeProductList.push(element);
          }
        }else if (type === "priceRange2" || type === "priceRange3") {
          if (element.price >= startRange && element.price <= endRange) {
            sortRangeProductList.push(element);
          }
        }else if (type === "priceRange4") {
          if (element.price >= endRange) {
            sortRangeProductList.push(element);
          }
        }
      });
      //this.productList = sortRangeProductList;
      this.onProductList.next(sortRangeProductList);
    } else {
      this.getProductRows();
    }
  }
  sortProduct(sortBy) {
    let sortDesc = false;
    const sortByKey = (() => {
      if (sortBy === "price-desc") {
        sortDesc = true;
        return "price";
      }
      if (sortBy === "price-asc") {
        return "price";
      }
      sortDesc = true;
      return "id";
    })();

    const sortedData = this.productList.sort(this.sortRef(sortByKey));
    if (sortDesc) sortedData.reverse();
    this.productList = sortedData;
    this.onProductList.next(this.productList);
  }
  addToCart(product) {
    let cart =[];
    const getCart:[] =this.storage.get("addCart");
    cart.push(product.id);
    if(getCart !== undefined){
      cart.push(...getCart);
    }
    this.storage.set("addCart",cart);
    this.onCartListChange.next(cart);
  }
  removeCartList(productId){
    let list = this.getCartList();
    delete list[productId];
    this.storage.remove("addCart");
    this.storage.set("addCart",list);
  }
  getCartList(){
    let listCart=[];
    let getCartList = this.storage.get("addCart");
    getCartList.forEach((item) => {
      if (item) {
        listCart[item] = item;
      }
    });
    return listCart;
  }
  removeCart(){
    this.storage.remove("addCart");
  }
setOrderNo(): Promise<any[]> {
    const url: string = "order";
    return new Promise((resolve, reject) => {
      this._httpService.getLocalStorageInfo("token").then((token) => {
        this._httpService.getHttpJsonGet(url, token).then((res) => {
          if (res.error != true) {
           // console.log(res.result.data);
            this.storage.set("ordesrNo","0000"+res.result.data.length+1);
            resolve(res);
          } else {
            reject(new Error("No data found."));
          }
        });
      });
    });
  }
  getOrderNo(){
    return this.storage.get("ordesrNo");
  }


}
