export const locale = {
    lang: 'th',
    data: {
      MENU: {
        DASHBOARD: {
          COLLAPSIBLE: 'สรุปภาพรวม',
          BADGE: '2',
          ANALYTICS: 'การวิเคราะห์',
          ECOMMERCE: 'อีคอมเมิร์ซ'
        },
        ACC:{
          SECTION: 'บัญชี',
          QUOTATION: {
            COLLAPSIBLE: 'ใบเสนอราคา',
            LIST: 'รายการ',
            PREVIEW: 'ดูตัวอย่าง',
            EDIT: 'แก้ไข',
            ADD: 'เพิ่ม'
          },
          INVOICE: {
            COLLAPSIBLE: 'ใบแจ้งหนี้',
            LIST: 'รายการ',
            PREVIEW: 'ดูตัวอย่าง',
            EDIT: 'แก้ไข',
            ADD: 'เพิ่ม'
          }
        },
        APPS: {
          SECTION: 'แอพ & เพจ',
          EMAIL: 'อีเมล',
          CHAT: 'แชท',
          TODO: 'รายการคิว',
          CALENDAR: 'ปฏิทิน',
  
          ECOMMERCE: {
            COLLAPSIBLE: 'อีคอมเมิร์ซ',
            SHOP: 'ร้านค้า',
            DETAIL: 'รายละเอียด',
            ORDERLIST:'รายการสั่งซื้อ',
            WISHLIST: 'สินค้าที่ต้องการ',
            CHECKOUT: 'เช็คเอาท์'
          },
          USER: {
            COLLAPSIBLE: 'จัดการผุ้ใช้งาน',
            LIST: 'รายการ',
            VIEW: 'ดู',
            EDIT: 'แก้ไข'
          },
          REPAIRE:{
            COLLAPSIBLE: 'ระบบแจ้งซ่อม',
            LIST: 'รายการ',
            VIEW: 'ดู',
            EDIT: 'แก้ไข'
          }
        },
        PAGES: {
          SECTION: 'หน้า',
          AUTH: {
            COLLAPSIBLE: 'การตรวจสอบสิทธิ์',
            LOGIN1: 'Login V1',
            LOGIN2: 'Login V2',
            REGISTER1: 'Register V1',
            REGISTER2: 'Register V2',
            FORGOTPASSWORD1: 'Forgot Password V1',
            FORGOTPASSWORD2: 'Forgot Password V2',
            RESETPASSWORD1: 'Reset Password V1',
            RESETPASSWORD2: 'Reset Password V2'
          },
          ACCOUNTSETTINGS: 'การตั้งค่าบัญชี',
          PROFILE: 'ประวัติโดยย่อ',
          FAQ: 'FAQ',
          KB: 'ฐานความรู้',
          PRICING: 'ราคา',
          BLOG: {
            COLLAPSIBLE: 'บล็อก',
            LIST: 'รายการ',
            DETAILS: 'รายละเอียด',
            EDIT: 'แก้ไข'
          },
          MAIL: {
            COLLAPSIBLE: 'เทมเพลตจดหมาย',
            WELCOME: 'ยินดีต้อนรับ',
            RESET: 'รีเซ็ตรหัสผ่าน',
            VERIFY: 'ตรวจสอบ',
            DEACTIVATE: 'ปิดการใช้งาน',
            INVOICE: 'ใบแจ้งหนี้',
            PROMOTIONAL: 'โปรโมชั่น'
          },
          MISC: {
            COLLAPSIBLE: 'เบ็ดเตล็ด',
            COMINGSOON: 'เร็วๆ นี้',
            NOTAUTH: 'ไม่มีสิทธิ์เข้าถึง',
            MAINTENANCE: 'การซ่อมบำรุง',
            ERROR: 'ข้อผิดพลาด'
          }
        },
        SYS:{
          SECTION: 'การตั้งค่าระบบ',
          CUSTOMER: 'จัดการลูกค้า',
          SUPPLIER: 'จัดการผู้ผลิต',
          TRUCK: 'จัดการรถ',
          SPAREPART: 'จัดการอะไหล่',
          DRIVER: 'จัดการคนขับ',
          PETROLSTATION: 'ข้อมูลปั๊มน้ำมัน',
          STONEMILL: 'ข้อมูลโรงโม่หิน',
          SANDPIT: 'ข้อมูลบ่อทราย',
          PRODUCT: 'จัดการสินค้า'
        },
        UI: {
          SECTION: 'User Interface',
          TYPOGRAPHY: 'Typography',
          COLORS: 'Colors',
          FEATHER: 'Feather',
          CARDS: {
            COLLAPSIBLE: 'Cards',
            BADGE: 'New',
            BASIC: 'Basic',
            ADVANCE: 'Advance',
            STATISTICS: 'Statistics',
            ANALYTICS: 'Analytics',
            ACTIONS: 'Actions'
          },
          COMPONENTS: {
            COLLAPSIBLE: 'Components',
            ALERTS: 'Alerts',
            AVATAR: 'Avatar',
            BADGES: 'Badges',
            BREADCRUMBS: 'Breadcrumbs',
            BUTTONS: 'Buttons',
            CAROUSEL: 'Carousel',
            COLLAPSE: 'Collapse',
            DIVIDER: 'Divider',
            DROPDOWNS: 'Dropdowns',
            GROUP: 'List Group',
            OBJECTS: 'Media Objects',
            MODALS: 'Modals',
            COMPONENT: 'Navs',
            PAGINATION: 'Pagination',
            PBADGES: 'Pill Badges',
            PILLS: 'Pills',
            POPOVERS: 'Popovers',
            PROGRESS: 'Progress',
            RATINGS: 'Ratings',
            SPINNER: 'Spinner',
            TABS: 'Tabs',
            TIMELINE: 'Timeline',
            TOASTS: 'Toasts',
            TOOLTIPS: 'Tooltips'
          },
          EX: {
            COLLAPSIBLE: 'Extension',
            SWEET_ALERTS: 'Sweet Alerts',
            BLOCKUI: 'BlockUI',
            TOASTER: 'Toastr',
            SLIDER: 'Slider',
            DRAGDROP: 'Drag & Drop',
            TOUR: 'Tour',
            CLIPBOARD: 'Clipboard',
            MEDIAPLAYER: 'Media Player',
            CONTEXTMENU: 'Context Menu',
            SWIPER: 'Swiper',
            TREEVIEW: 'Tree View',
            I18N: 'I18n'
          },
          LAYOUTS: {
            COLLAPSIBLE: 'Page Layouts',
            COLLAPSED_MENU: 'Collapsed Menu',
            BOXED_LAYOUT: 'Boxed Layout',
            WITHOUT_MENU: 'Without Menu',
            LAYOUT_EMPTY: 'Layout Empty',
            LAYOUT_BLANK: 'Layout Blank'
          }
        },
        FT: {
          SECTION: 'Forms & Tables',
          ELEMENT: {
            COLLAPSIBLE: 'Form Elements',
            INPUT: 'Input',
            INPUTGROUPS: 'Input Groups',
            INPUTMASK: 'Input Mask',
            TEXTAREA: 'Textarea',
            CHECKBOX: 'Checkbox',
            RADIO: 'Radio',
            SWITCH: 'Switch',
            SELECT: 'Select',
            NUMBERINPUT: 'Number Input',
            FILEUPLOADER: 'File Uploader',
            QUILLEDITOR: 'Quill Editor',
            FLATPICKER: 'Flatpicker',
            DATETIMEPICKER: 'Date & Time Picker'
          },
          LAYOUTS: 'Form Layouts',
          WIZARD: 'Form Wizard',
          VALIDATION: 'Form Validations',
          REPEATER: 'Form Repeater',
          TABLE: 'Table',
          DATATABLES: 'DataTables'
        },
        CM: {
          SECTION: 'แผนภูมิและแผนที่',
          CHARTS: {
            COLLAPSIBLE: 'ชาร์ต',
            BADGE: '2',
            APEX: 'Apex',
            CHARTJS: 'ChartJS'
          },
          MAPS: 'จัดการคิว'
        },
        OTHERS: {
          SECTION: 'อื่นๆ',
          LEVELS: {
            COLLAPSIBLE: 'Menu Levels',
            SECOND: 'Second Level',
            SECOND1: {
              COLLAPSIBLE: 'Second Level',
              THIRD: 'Third Level',
              THIRD1: 'Third Level'
            }
          },
          DISABLED: 'Disabled Menu',
          DOCUMENTATION: 'คู่มือ',
          SUPPORT: 'ช่วยเหลือ'
        }
      }

    }
  };
  