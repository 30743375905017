import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'Dashboard',
    title: 'สรุปภาพรวม',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'item',
    icon: 'home',
    url: 'dashboard',
    //role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
  },
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: 'แอปพลิเคชั่น',
    translate: 'MENU.APPS.SECTION',
    icon: 'package',
    children: [
      {
        id: 'chat',
        title: 'พูดคุย',
        translate: 'MENU.APPS.CHAT',
        type: 'item',
        icon: 'message-square',
        url: 'apps/chat'
      },
      {
        id: 'queue',
        title: 'จัดการคิว',
        translate: 'MENU.CM.MAPS',
        icon: 'map',
        type: 'item',
        url: 'apps/queue/list'
      },
     /* {
        id: 'que',
        title: 'จัดการคิว',
        translate: 'MENU.CM.MAPS',
        icon: 'map',
        type: 'item',
        url: 'apps/delivery/delivery-maps'
      },*/
      // {
      //   id: 'quelist',
      //   title: 'รายการคิว',
      //   translate: 'MENU.APPS.TODO',
      //   type: 'item',
      //   icon: 'check-square',
      //   url: 'apps/todo'
      // },
      {
        id: 'e-commerce',
        title: 'ร้านค้า',
        translate: 'MENU.APPS.ECOMMERCE.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'shopping-cart',
        children: [
          {
            id: 'shop',
            title: 'รายการสินค้า',
            translate: 'MENU.APPS.ECOMMERCE.SHOP',
            type: 'item',
            icon: 'circle',
            url: 'apps/e-commerce/shop'
          },
          
          {
            id: 'checkout',
            title: 'ชำระเงิน',
            translate: 'MENU.APPS.ECOMMERCE.CHECKOUT',
            type: 'item',
            icon: 'circle',
            url: 'apps/e-commerce/checkout'
          }
        ]
      },
      {
        id: 'repaire',
        title: 'ระบบแจ้งซ่อม',
        translate: 'MENU.APPS.REPAIRE.COLLAPSIBLE',
        type: 'item',
        icon: 'message-square',
        url: 'apps/repair/repair-list'
      },
      {
        id: 'users',
        title: 'จัดการผุ้ใช้งาน',
        translate: 'MENU.APPS.USER.COLLAPSIBLE',
        type: 'item',
        icon: 'user',
        url: 'apps/user/user-list'
      }
    ]
  },

  //Account
    {
      id:'account',
      type:'section',
      title:'บัญชี',
      translate:'MENU.ACC.SECTION',
      icon:'layers',
      children:[
          {
            id: 'orderlist',
            title: 'รายการสั่งซื้อ',
            translate: 'MENU.APPS.ECOMMERCE.ORDERLIST',
            type: 'item',
            icon: 'file-text',
            url: 'apps/invoice/list'
          }
      ]
    },
    // System setting
    {
      id: 'system-setting',
      type: 'section',
      title: 'System Setting',
      translate: 'MENU.SYS.SECTION',
      icon: 'layers',
      children: [
        {
          id: 'product',
          title: 'จัดการสินค้า',
          translate: 'MENU.SYS.PRODUCT',
          type: 'item',
          icon: 'box',
          url: 'apps/product/product-list'
        },
        {
          id: 'customer',
          title: 'จัดการลูกค้า',
          translate: 'MENU.SYS.CUSTOMER',
          type: 'item',
          icon: 'trello',
          url: 'apps/customer/customer-list'
        },
        {
          id: 'supplier',
          title: 'จัดการผู้ผลิต',
          translate: 'MENU.SYS.SUPPLIER',
          type: 'item',
          icon: 'book',
          url: 'apps/supplier/supplier-list'
        },
        {
          id: 'truck',
          title: 'จัดการรถ',
          translate: 'MENU.SYS.TRUCK',
          type: 'item',
          icon: 'truck',
          url: 'apps/truck/truck-list'
        },
        {
          id: 'sparepart',
          title: 'จัดการอะไหล่',
          translate: 'MENU.SYS.SPAREPART',
          type: 'item',
          icon: 'sliders',
          url: 'apps/sparepart/sparepart-list'
        },
        {
          id: 'driver',
          title: 'จัดการคนขับ',
          translate: 'MENU.SYS.DRIVER',
          type: 'item',
          icon: 'users',
          url: 'apps/driver/driver-list'
        },
        {
          id: 'petrostation',
          title: 'ข้อมูลปั๊มน้ำมัน',
          translate: 'MENU.SYS.PETROLSTATION',
          type: 'item',
          icon: 'droplet',
          url: 'apps/petro/petro-list'
        },
        {
          id: 'stonemill',
          title: 'ข้อมูลโรงโม่หิน',
          translate: 'MENU.SYS.STONEMILL',
          type: 'item',
          icon: 'package',
          url: 'apps/stonemill/stonemill-list'
        },
        {
          id: 'sandpit',
          title: 'ข้อมูลบ่อทราย',
          translate: 'MENU.SYS.SANDPIT',
          type: 'item',
          icon: 'slack',
          url: 'apps/sandpit/sandpit-list'
        }
      ]
    },


  // Charts & Maps
  /*{
    id: 'charts-maps',
    type: 'section',
    title: 'Charts & Report',
    translate: 'MENU.CM.SECTION',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'charts',
        title: 'ระบบรายงาน',
        translate: 'MENU.CM.CHARTS.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'pie-chart',
        badge: {
          title: '2',
          translate: 'MENU.CM.CHARTS.BADGE',
          classes: 'badge-light-danger badge-pill'
        },
        children: [
          {
            id: 'apex',
            title: 'Apex',
            translate: 'MENU.CM.CHARTS.APEX',
            type: 'item',
            icon: 'circle',
            url: 'charts-and-maps/apex'
          },
          {
            id: 'chartJs',
            title: 'ChartJS',
            translate: 'MENU.CM.CHARTS.CHARTJS',
            type: 'item',
            icon: 'circle',
            url: 'charts-and-maps/chartjs'
          }
        ]
      }

    ]
  },
  {
    id: 'others',
    type: 'section',
    title: 'Others',
    translate: 'MENU.OTHERS.SECTION',
    icon: 'box',
    children: [

      {
        id: 'documentation',
        title: 'Documentation',
        translate: 'MENU.OTHERS.DOCUMENTATION',
        icon: 'file-text',
        type: 'item',
        url: 'https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation',
        externalUrl: true,
        openInNewTab: true,
        //disabled: true
      },
      {
        id: 'raise-support',
        title: 'Support',
        translate: 'MENU.OTHERS.SUPPORT',
        icon: 'life-buoy',
        type: 'item',
        url: 'https://pixinvent.ticksy.com/',
        externalUrl: true,
        openInNewTab: true
      }
    ]
  }*/
];
